import { createStore, combineReducers} from 'redux';
// import { reducer as permissions } from 'react-redux-permissions';

// import thunk from 'redux-thunk';
import general from '../general_redux';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    combineReducers({
        general,
        // permissions,
    }),
);

export default store;
