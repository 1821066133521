import { API, Auth } from "aws-amplify";

export async function listFunction(path) {
  try {
    return await API.get("list", path, {})
      .then(data => {
        return data;
      })
      .catch(err => {
        return err;
      });
  } catch (e) {
    console.log(e);
  }
}

export async function getRestaurants() {
  try {
    const restaurantsData = await API.get("list", "/list/restaurant", {});
    return restaurantsData;
  } catch (e) {
    console.log(e);
  }
}

export async function userLoggedinOrNot() {
  return await Auth.currentAuthenticatedUser()
    .then(user => {
      return user;
    })
    .catch(() => console.log("Not signed in"));
}

export async function signUp(username, password) {
  try {
    return await Auth.signUp({
      username: username,
      password: password
    })
      .then(data => {
        return data;
      })
      .catch(err => {
        return err;
      });
  } catch (e) {
    console.log(e);
  }
}

export async function addUser(
  clubId,
  userId,
  firstName,
  lastName,
  phoneNumber,
  profile,
  clubName,
  email
) {
  try {
    return await API.post("users", "/api/users", {
      body: {
        firstName: firstName,
        lastName: lastName,
        userId: userId,
        phone_number: phoneNumber,
        profile: profile,
        clubName: clubName,
        clubId: clubId,
        email: email
      }
    })
      .then(data => {
        return data;
      })
      .catch(err => {
        return err;
      });
  } catch (e) {
    console.log(e);
  }
}

export async function getUser(userId) {
  try {
    return await API.get("get", "/general/get/getDet/" + userId, {});
  } catch (e) {
    console.log("Error");
    console.log(e.message);
    console.log(e);
  }
}

export async function getBreedAndSpecies() {
  try {
    return await API.get("get", "/general/get/getBreedAndTest?orgId="+localStorage.getItem('orgId'), {});
  } catch(e) {
    console.log(e.message);
  }
}

export async function getRestaurantInfo(restaurantId) {
  try {
    return await API.get("get", "/api/get/restaurant/" + restaurantId, {})
      .then(data => {
        return data;
      })
      .catch(err => {
        return err;
      });
  } catch (e) {
    console.log(e);
  }
}

export async function getFunction(path) {
  try {
    return await API.get("get", path, {})
      .then(data => {
        return data;
      })
      .catch(err => {
        return {"statuscode": 1, "msg": err};
      });
  } catch (e) {
    console.log(e);
  }
}

export async function postCall(path,data) {
  try {
      let res = await API.post('create', path, {
          body:{
            "entityData":data
          }
      })
      return {"data":res,"statusCode":1}
  } catch(e) {
      if (e.response.data) {
          alert(e.response.data.message);
      } else {
          alert("Something went wrong!");
      }
      return {"data":e,"statusCode":0}
  }
}

//Validating email
export function  validateEmail(email){
  let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  if(!emailValid) {
    alert("Please enter a valid email address!");
    return false;
  }
  return true;
}

export function validatePhone(phone) {
  let firstCharacter = phone[0];
  let phoneNumber = phone.replace(/[^0-9]/g, "");
  phoneNumber = firstCharacter === "+"?"+"+phoneNumber:"+1"+phoneNumber;
  if((phoneNumber.substring(0,2) === "+1" && phoneNumber.length !== 12) || (phoneNumber.substring(0,2) === "+9" && phoneNumber.length !== 13)) {
    alert("Please enter valid phone number!");
    return "false";
  }
  return phoneNumber;
}