import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';

import  {Provider} from 'react-redux';
import store from './config/store'
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify from "aws-amplify";
import config from "./config";
// import Routes from './Adminroutes';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "list",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },{
        name: "create",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },{
        name: "items",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },{
        name: "update",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },{
        name: "delete",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },{
        name: "get",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },{
        name: "forgotPassword",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});

ReactDOM.render(
  <Provider store = {store}>
    <App/>
  </Provider>,
   document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();