export default {
    s3: {
      REGION: process.env.REACT_APP_S3_REGION,
      BUCKET: process.env.REACT_APP_S3_BUCKET
    },
    apiGateway: {
      REGION: process.env.REACT_APP_API_REGION,
      URL: process.env.REACT_APP_API_GATEWAY
    },
    cognito: {
      REGION: process.env.REACT_APP_COGNITO_REGION,
      USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID
    },
    MAX_ATTACHMENT_SIZE: process.env.REACT_APP_MAX_ATTACHMENT_SIZE,
    GET_ORDERS_API:"/general/get/getOrderDetails",
    GET_INVOICES_API:"/general/list/invoices",
    DELETE_ORDER_API:"/general/delete/orders/",

    CREATE_ORG_API:"/general/create/organizations",
    UPDATE_ORG_API:"/general/updateRecord/organizations",
    DELETE_ORGANIZATION_API:"/general/delete/organizations/",

    GET_TESTS_API:"/general/list/test",
    GET_CLIENTS_API:"/general/list/getClientsData",
    GET_BREEDS_AND_TESTS_API:"/general/get/getBreedAndTest",
    GET_USERS_API:"/general/list/users",
    GET_LATEST_RESULTS: "/general/list/getLatestResult",
    GET_PAYMENT_CODES_API:"/general/list/paymentCodes",
    GET_MASTER_DATA_API:"/general/list/masterData",
    GET_PAYMENT_CARDS_API:"/general/list/userPaymentCards",
    STRIPE_KEY:process.env.REACT_APP_STRIPE_KEY
  };