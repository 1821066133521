import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import { userLoggedinOrNot } from "./lib/commonFunctions";
import IdleTimer from 'react-idle-timer';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login/Login'),
  loading
});

const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword/ForgotPassword'));
const CofirmForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword/CofirmForgotPassword'));

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500/Page500'),
  loading
});

class App extends Component {
  async componentDidMount() {
    const user = await userLoggedinOrNot();
    if (user) {
      console.log("User Logged In");
    }
    else {
      console.log("User not Logged In")
      localStorage.clear()
    }
  }

  render() {
    return (
      <>
      <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/admin" name="Admin Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/forgot_password" name="Forgot Page" render={props => <ForgotPassword {...props}/>} />
            <Route exact path="/confirm_forgot_password" name="Confirm Forgot Page" render={props => <CofirmForgotPassword {...props}/>} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
          </React.Suspense>
      </HashRouter>
      </>
    );
  }
}

export default App;
